import logo1 from "../assets/images/logo1.webp";
import logo2 from "../assets/images/logo2.webp";
import audaz from "../assets/images/logo3.webp";
import logo4 from "../assets/images/logo4.webp";
import bioghar from "../assets/images/logo5.webp";
import shammovers from "../assets/images/logo6.webp";
import qasralrand from "../assets/images/logo7.webp";
import kenz from "../assets/images/logo8.webp";
import windex from "../assets/images/logo9.webp";
import biggestcoin from "../assets/images/logo10.webp";
import logo from "../assets/images/logos.jfif";
import advertising from "../assets/images/Advertising/advertising1.webp";
import publication from "../assets/images/Publications/Publication6.webp";
import webdevelpment from "../assets/images/webdevelpment.webp";
import web1 from "../assets/images/webdevelopment/biggestcoin.jpg";
import web2 from "../assets/images/webdevelopment/shammovers.png";
import web3 from "../assets/images/webdevelopment/hkayat.png";
import web4 from "../assets/images/webdevelopment/jananet.png";
import web5 from "../assets/images/webdevelopment/bioghar.png";
import web6 from "../assets/images/webdevelopment/dizifood.png";
import web7 from "../assets/images/webdevelopment/firstgulf.png";
import web8 from "../assets/images/webdevelopment/oil.png";
import web9 from "../assets/images/webdevelopment/reyceclewebsite.png";
import web10 from "../assets/images/webdevelopment/foryouwebsite.png";
import web11 from "../assets/images/webdevelopment/nooralhodajewelry.webp";

export const logos = [
  webdevelpment,
  audaz,
  bioghar,
  shammovers,
  qasralrand,
  kenz,
  windex,
  biggestcoin,
  logo,
  advertising,
  publication,
];

export const companyName = [
  ["Web Development", "تطوير المواقع الالكترونية"],
  ["Audaz Properties", "اوداز العقارية"],
  ["BioGhar", "بيوغار"],
  ["Sham Movers", "شام للنقليات"],
  ["Qasr Alrand", "قصر الرند"],
  ["Kenz", "كينز"],
  ["Car Wash windex", "ويندكس"],
  ["the Biggest Coin", "اكبر عملة نقدية بالعالم"],
  ["Logos", "شعارات"],
  ["Advertising Posts", "منشورات إعلانية"],
  ["Publication", "مطبوعات"],
];

export const links = [
  "/portfolio/websites",
  "/company/audaz",
  "/company/bioghar",
  "/company/ShamMovers",
  "/company/Qasr-Alrand",
  "/company/Kenz",
  "/company/Windex",
  "/company/biggest-coin",
  "/company/Logos",
  "/company/AdvertisingPosts",
  "/company/Publication",
];

export let websitesWork = [
  {
    id: "the biggest coin",
    image: web1,
    name: "The Biggest Coin",
    description:["The largest painting of Sheikh Zayed Al Nahyan 'Mercy of God on him' in coins of the countries of the world The Emirate of Dubai","أكبر لوحة للشيخ زايد آل نهيان رحمه الله بالعملات المعدنية لدول العالم إمارة دبي"],
    link: "https://thebiggestcoin.com",
  },
  {
    id: "sham movers",
    image: web2,
    name: "Sham Movers",
    description:[ "Dubai Packaging And Movers Company Limited, Syrian Arabic Company","شركة عربية سورية في دبي تعمل في مجال النقل والتغليف"]
     ,
    link: "https://shammovers.net",
  },
  {
    id: "Hkayat Resturent",
    image: web3,
    name: "Hkayat Resturent",
    description:[ "Specialty Coffee Shop and Levant food kitchen, where we combine the best of both worlds to offer you a unique culinary experience"," مطعم يقدم قهوة مميزة بالإضافة لمأكولات شامية "]
     ,
    link: "https://hkayat.net",
  },
  {
    id: "janna net",
    image: web4,
    name: "Janna Net Online Store",
    description: ["janna net,the best online shopping experience","متجر الكتروني لبيع المنتجات"],
    link: "https://jannanet.com/",
  },
  {
    id: "bioghar",
    image: web5,
    name: "Bioghar",
    description:[ "Bioghar company was established in Dubai launching from Syria to gather the natural components to put them into your hands.","متجر الكتروني متخصص في بيع مستحضرات العناية بالبشرة"]
     ,
    link: "https://bioghar.ae",
  },
  {
    id: "dizifood",
    image: web6,
    name: "dizifood",
    description:[ "restaurant aims to serve you the most delicious and delightful dish","  مطعم سحابي يقدم  أشهى وأطيب الأطباق"]
     ,
    link: "https://dizifood.net/",
  },
  {
    id: "first gulf",
    image: web7,
    name: "First Gulf Company",
    description:[ "Gulf First Trading Company is a factory for producing chocolates and cakes in Dhofar Governorate, Sultanate of Oman.","  شركة الخليج الأول تعمل في مجال صناعة الشوكولا الفاخرة بالإضافة الى الكيك"]
     ,
    link: "https://firstgulf-llc.net/",
  },
  {
    id: "Almusafir International",
    image: web8,
    name: "Almusafir International",
    description:[ "leading company in the field of selling high-quality oils, tires, and car batteries.","  شركة رائدة في مجال بيع زيوت السيارات والإطارات والبطاريات"]
     ,
    link: "https://musafirtiresoil.com/",
  },
  {
    id: "Recycle alzaabi",
    image: web9,
    name: "Recycle Ali Alzaabi",
    description:[ "A volunteer in United Arab Emirates in many volunteer fields ","متطوع على مستوى دولة الإمارات العربية المتحدة في العديد من المجالات التطوعية  "]
     ,
    link: "https://recyclealzaabi.com/",
  },
  {
    id: "For you Cleaning company",
    image: web10,
    name: "For you Cleaning company",
    description:[ "Cleaning company specializing in providing professional cleaning services","  شركة تنظيف متخصصة في تقديم خدمات التنظيف الاحترافية للعملاء "]
     ,
    link: "https://foryoucleaning.com/",
  },
  {
    id: " Noor Al Huda jewelry",
    image: web11,
    name: "Noor Al Huda jewelry",
    description:[ "Noor Alhoda jewelry , that was established in 2019. We take pride in providing you with a unique and exceptional shopping experience in the world of gold jewelry.","  تأسست مجوهرات نور الهدى في عام 2019. نحن نفخر بتقديم تجربة تسوق فريدة واستثنائية في عالم المجوهرات الذهبية.         "]
     ,
    link: "https://nooralhodajewel.com/",
  },
];
