import "./OtherServices.css";
import { Container, Row, Col } from "react-bootstrap";
import icon1 from "../../assets/images/ecommerce.png";
import icon2 from "../../assets/images/code.webp";
import icon3 from "../../assets/images/digitalIcon.webp";
import mobileAppDev from "../../assets/images/mobile-app-dev.png";
import maintenanceSytemImg from "../../assets/images/maintenance-system.png";
import ecommerce from "../../assets/images/eco-icon.webp";
import webdevelpment from "../../assets/images/webdevelpment.webp";
import hostIcon from "../../assets/images/host-icon.webp";
import digital from "../../assets/images/digital.webp";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import Header from "../../components/utility/header/Header";
import { Helmet } from "react-helmet-async";

const OtherServices = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const {
    text1,
    text2,
    text3,
    text4,
    text5,
    text6,
    text7,
    text8,
    text9,
    text10,
    text11,
    DigitalMarketing,
    DigitalMarketingdes,
  } = {
    ...translate.otherServices
  };
  return (
    <>
      {indexTranslate === 0 ? (
        <Helmet>
        <meta property="og:site_name" content="Takfil | Digital Marketing Agency" />
          <meta
            name="description"
            content="اقتراح حلول لزيادة العملاء والعائد من الإستثمار واعتماد آخر استراتيجيات والتقنيات لزيادة المبيعات والتفوق على المنافسين في مجال التجارة الإلكترونية وتدريب العملاء على إدارة أشهر المتاجر الإلكترونية منها (Wordpress-opencart)"
          />
          <meta name="keyword" content="" />
          <title>Takfil Digital Marketing </title>
        </Helmet>
      ) : (
        <Helmet>
          <meta
            name="description"
            content="اقتراح حلول لزيادة العملاء والعائد من الإستثمار واعتماد آخر استراتيجيات والتقنيات لزيادة المبيعات والتفوق على المنافسين في مجال التجارة الإلكترونية وتدريب العملاء على إدارة أشهر المتاجر الإلكترونية منها (Wordpress-opencart)"
          />

          <meta
            name="description"
            content="إنشاء وإدارة الحملات الإعلانية على مختلف منصات التواصل الإجتماعي فيسبوك انستغرام واتساب تيكتوك يوتيوب وكذلك إدارة الحملات الإعلانية الممولة على أشهر محركات البحث جوجل ادس وبينج ادس تحسين محتوى المواقع ورفع ترتيبها في نتائج محركات البحث بالإعتماد على أدوات التحليل المتقدمة (sumrush-google analyctis)"
          />
          <meta
            name="keywords"
            content="شركة تصميم مواقع الويب دبي ، شركة تطوير مواقع
الويب دبي ، خدمة تصميم مواقع الويب ، تطوير مواقع الويب ، خدمات تصميم مواقع
الويب دبي, مصممين المواقع الالكترونية في دبي"
          ></meta>
          <meta name="keyword" content="" />
          <title>  تكفيل للتسويق الرقمي </title>
        </Helmet>
      )}
      {indexTranslate === 0 ? (
        <div className="takfil-digital">
          <Header
            link="/otherServices"
            secondPage={translate.nav.otherServices[indexTranslate]}
          />
          <Container>
            <div className="digital-container">
              <Row>
                <Col lg="4">
                  <div className="digital-box">
                    <img src={icon1} alt="icon-e-commerce" loading="lazy" />
                    <h2>
                      {
                        translate.otherServices.Ecommercedevelopment[
                          indexTranslate
                        ]
                      }
                    </h2>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="digital-box">
                    <img src={icon2} alt="icon-web-design" loading="lazy" />
                    <h2>
                      {
                        translate.otherServices.Websitedesignanddevelopment[
                          indexTranslate
                        ]
                      }
                    </h2>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="digital-box">
                    <img src={icon3} alt="icon-e-commerce" loading="lazy" />
                    <h2>{DigitalMarketing[indexTranslate]}</h2>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <div className="digital-box-des">

                    <p>
                      <img src={ecommerce} alt="icon" loading="lazy" />{" "}
                      <a href=" https://www.takfil.ae"> 
                      <div className="btnMore"> 
                        {translate.main.btnViewWebsite[indexTranslate]} 
                      </div>
                      </a>
                      {text1[indexTranslate]}

                    </p>

                    <p>
                      <img src={mobileAppDev} alt="icon" loading="lazy" />{" "}
                      <a href=" https://www.takfil.ae"> 
                      <div className="btnMore"> 
                        {translate.main.btnViewWebsite[indexTranslate]} 
                      </div>
                      </a>
                      {text11[indexTranslate]}

                    </p>

                    <p>
                      <img src={maintenanceSytemImg} alt="icon" loading="lazy" />{" "}
                      <a href=" https://www.takfil.ae"> 
                      <div className="btnMore"> 
                        {translate.main.btnViewWebsite[indexTranslate]} 
                      </div>
                      </a>
                      {text10[indexTranslate]}

                    </p>

                    <p>
                      <img src={webdevelpment} alt="" loading="lazy" />
                      <a href=" https://www.takfil.ae"> 
                      <div className="btnMore"> 
                        {translate.main.btnViewWebsite[indexTranslate]} 
                      </div>
                      </a>
                      {text2[indexTranslate]} <br />
                      {text5[indexTranslate]}
                      <br />
                      {text6[indexTranslate]}
                      <br />
                    </p>

                    <p>
                      <img src={hostIcon} alt="icon" />
                      <a href=" https://www.takfil.ae"> 
                      <div className="btnMore"> 
                        {translate.main.btnViewWebsite[indexTranslate]} 
                      </div>
                      </a>
                      {text3[indexTranslate]}
                      <br />
                      {text4[indexTranslate]}
                      <br />
                      {text7[indexTranslate]}
                      <br />
                      {text8[indexTranslate]}
                      <br />
                      {text9[indexTranslate]}
                      <br />
                    </p>

                    <p>
                      <img src={digital} alt="" loading="lazy" />
                      <a href=" https://www.takfil.ae"> 
                      <div className="btnMore"> 
                        {translate.main.btnViewWebsite[indexTranslate]} 
                      </div>
                      </a>
                      {DigitalMarketingdes[indexTranslate]}
                    </p>

                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      ) : (
        <div className="takfil-digital" style={{ direction: "rtl" }}>
          <Header
            link="/otherServices"
            secondPage={translate.nav.otherServices[indexTranslate]}
          />
          <Container>
            <div className="digital-container">
              <Row>
                <Col lg="4">
                  <div className="digital-box">
                    <img src={icon1} alt="icon-e-commerce" loading="lazy" />
                    
                    <h2>
                      {translate.otherServices.Ecommercedevelopment[indexTranslate] }
                    </h2>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="digital-box">
                    <img src={icon2} alt="icon-web-design" loading="lazy" />
                    <h2>
                      {
                        translate.otherServices.Websitedesignanddevelopment[
                          indexTranslate
                        ]
                      }
                    </h2>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="digital-box">
                    <img src={icon3} alt="icon-e-commerce" loading="lazy" />
                    <h2>{DigitalMarketing[indexTranslate]}</h2>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <div className="digital-box-des">
                  <p>
                      <img src={ecommerce} alt="icon" loading="lazy" />{" "}
                      <a href=" https://www.takfil.ae"> 
                      <div className="btnMore"> 
                        {translate.main.btnViewWebsite[indexTranslate]} 
                      </div>
                      </a>
                      {text1[indexTranslate]}

                    </p>

                    <p>
                      <img src={mobileAppDev} alt="icon" loading="lazy" />{" "}
                      <a href=" https://www.takfil.ae"> 
                      <div className="btnMore"> 
                        {translate.main.btnViewWebsite[indexTranslate]} 
                      </div>
                      </a>
                      {text11[indexTranslate]}

                    </p>

                    <p>
                      <img src={maintenanceSytemImg} alt="icon" loading="lazy" />{" "}
                      <a href=" https://www.takfil.ae"> 
                      <div className="btnMore"> 
                        {translate.main.btnViewWebsite[indexTranslate]} 
                      </div>
                      </a>
                      {text10[indexTranslate]}

                    </p>

                    <p>
                      <img src={webdevelpment} alt="" loading="lazy" />
                      <a href=" https://www.takfil.ae"> 
                      <div className="btnMore"> 
                        {translate.main.btnViewWebsite[indexTranslate]} 
                      </div>
                      </a>
                      {text2[indexTranslate]} <br />
                      {text5[indexTranslate]}
                      <br />
                      {text6[indexTranslate]}
                      <br />
                    </p>

                    <p>
                      <img src={hostIcon} alt="icon" />
                      <a href=" https://www.takfil.ae"> 
                      <div className="btnMore"> 
                        {translate.main.btnViewWebsite[indexTranslate]} 
                      </div>
                      </a>
                      {text3[indexTranslate]}
                      <br />
                      {text4[indexTranslate]}
                      <br />
                      {text7[indexTranslate]}
                      <br />
                      {text8[indexTranslate]}
                      <br />
                      {text9[indexTranslate]}
                      <br />
                    </p>

                    <p>
                      <img src={digital} alt="" loading="lazy" />
                      <a href=" https://www.takfil.ae"> 
                      <div className="btnMore"> 
                        {translate.main.btnViewWebsite[indexTranslate]} 
                      </div>
                      </a>
                      {DigitalMarketingdes[indexTranslate]}
                    </p>

                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default OtherServices;
