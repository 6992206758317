import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import "./main.css";
import axios from "axios";
import notify from "../../hook/useNotifaction";
import slide1 from "../../assets/images/slide1.jpg";
import slide2 from "../../assets/images/slide2.jpg";
import slide3 from "../../assets/images/slide3.jpg";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import video from "../../assets/videos/Main-1.webm";

const MainSection = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const [email, setEmail] = useState(undefined);
  const { mail } = { ...translate.main };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (email !== "") {
      formData.append("email", email);
    }
    axios
      .post("php/mail.php", formData)
      .then((response) => {
        if (response.status === 200) {
          notify(mail[indexTranslate], "success");
        }
      })
      .catch((error) => {
        notify(
          indexTranslate === 0
            ? "Failed to send the email try again"
            : "فشل ارسال الايميل حاول مرة اخرى ",
          "error"
        );
      });

    setEmail("");
  };
  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <>
      {indexTranslate === 0 ? (
        <div className="main-section">
          <Carousel fade>
            <Carousel.Item interval={3000}>
              <div className="image-carousel">
                <img className="d-block w-100" src={slide1} alt="First slide" />
                <div className="text-carousel"></div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <div className="image-carousel">
                <img
                  className="d-block w-100"
                  src={slide2}
                  alt="Second slide"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item interval={4000} >
              <div className="image-carousel " >
                <img
                  className="d-block w-100"
                  src={slide3}
                  alt="Second slide"
                />
              </div>

              <Carousel.Caption>
                <div className="main-form my-4">
                  <HiOutlineSpeakerphone className="fa-5x" />
                  <h5>{translate.main.GetStartedToday[indexTranslate]}</h5>
                  <p>{translate.main.textForm[indexTranslate]}</p>
                  <form>
                    <input
                      type="text"
                      placeholder="Enter your email   "
                      name="email"
                      onChange={handleChange}
                      value={email}
                    ></input>
                    <input
                      type="submit"
                      value={translate.send[indexTranslate]}
                      onClick={handleSubmit}
                    />
                  </form>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      ) : (
        <div className="main-section" style={{ direction: "rtl" }}>
          <Carousel fade>
            <Carousel.Item interval={2000}>
              <div className="image-carousel">
                <img className="d-block w-100" src={slide1} alt="First slide" />
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="image-carousel">
                <img
                  className="d-block w-100"
                  src={slide2}
                  alt="Second slide"
                />
              </div>
            </Carousel.Item>

            <Carousel.Item interval={4000}>
              <div className="image-carousel">
                <img
                  className="d-block w-100"
                  src={slide3}
                  alt="Second slide"
                />
              </div>

              <Carousel.Caption>
                <div className="main-form my-4">
                  <HiOutlineSpeakerphone className="fa-5x" />
                  <h5>{translate.main.GetStartedToday[indexTranslate]}</h5>
                  <p>{translate.main.textForm[indexTranslate]}</p>
                  <form>
                    <input
                      type="text"
                      placeholder="ادخل الايميل الخاص بك"
                      name="email"
                      onChange={handleChange}
                      value={email}
                    ></input>
                    <input
                      type="submit"
                      value={translate.send[indexTranslate]}
                      onClick={handleSubmit}
                    />
                  </form>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      )}
    </>
  );
};

export default MainSection;
