import { useRecoilState } from "recoil";
import IndexTranslate from "../../atoms/translateIndex";
import Frame from "../../components/utility/frame/Frame";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { fadeIn } from "../../utils/motion";
import { motion } from "framer-motion";
import { websitesWork } from "../../data/data";
import { Tilt } from "react-tilt";
import { Suspense } from "react";

const WebDevelopmentCard = ({ index, image, name, description, link }) => {
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <Col md="3" className="my-4 mx-auto">
      <Tilt>
        <motion.div variants={fadeIn("up", "spring", index * 0.5, 0.75)}>
          <Card style={{ width: "18rem",border: "none",boxShadow:"0 0 8px #ddd"}} className="mx-auto">
            <Suspense
              fallback={<Spinner animation="border" variant="secondary" />}
            >
              <Card.Img
                variant="top"
                src={image}
                style={{ width: "100% !important", objectFit: "cover" }}
              />
            </Suspense>
            <Card.Body>
              <Card.Title>{name}</Card.Title>
              <Card.Text
                style={{ height: "110px" }}
                className="d-flex justify-content-center align-items-center"
              >
                {description[indexTranslate]}
              </Card.Text>
              <Button
                variant="secondary "
                onClick={() => {
                  window.open(link, "_blank");
                }}
              >
                {indexTranslate === 0 ? "Visit Website" : "زيارة الموقع"}
              </Button>
            </Card.Body>
          </Card>
        </motion.div>
      </Tilt>
    </Col>
  );
};

const WebDevelopment = () => {
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <div className="logos-page">
      {indexTranslate === 0 ? (
        <Frame title="Web Development" />
      ) : (
        <Frame title="تطوير المواقع الالكترونية " />
      )}
      <Container>
        <Row>
          {websitesWork.map((website, index) => (
            <WebDevelopmentCard
              index={index}
              key={website.id}
              name={website.name}
              image={website.image}
              description={website.description}
              link={website.link}
            />
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default WebDevelopment;
