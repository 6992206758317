import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import video1 from "../../assets/videos/video2.webm";
import video2 from "../../assets/videos/video2.mp4";
import "./section3.css";
import { TbReceiptTax } from "react-icons/tb";
import { AiOutlineAudit } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import { AiOutlineForm } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import { HiCalculator } from "react-icons/hi";

const Section3 = () => { 
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const {
    title,
    description,
    icon1title,
    icon1des,
    icon2title,
    icon2des,
    icon3title,
    icon3des,
    icon4title,
    icon4des,
    icon5title,
    icon5des,
    icon6title,
    icon6des, 
  } = {
    ...translate.section3,
  };
  return (
    <>
      {indexTranslate === 0 ? (
        <div className="section-3" >
          <Container>
            <Row className="section-3-container">
              <Col lg="12">
                <Row>
                  <Col lg="12" > 
                    <h1 className="titleSection"> {title[indexTranslate]} </h1>
                    <div className="titleUnderLine" ></div>

                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <div className="box-section-3">
                      <div className="box-content-section-3">
                        
                        <div className="divIcon"> <TbReceiptTax className="icon"/> </div>

                        <h6>{icon1title[indexTranslate]}</h6>
                        
                        <p>{icon1des[indexTranslate]}</p>
                     
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="box-section-3 ">
                      <div className="box-content-section-3">
                        <div className="divIcon"> <HiCalculator className="icon"/> </div>
                        <h6>{icon3title[indexTranslate]}</h6>
                        
                        <p>{icon3des[indexTranslate]}</p>
                      </div>
                    </div>
                  </Col>
                  
                  <Col lg="4">
                    <div className="box-section-3 ">
                      <div className="box-content-section-3">
                        <div className="divIcon"> <AiOutlineAudit className="icon"/> </div>
                        <h6>{icon5title[indexTranslate]}</h6>
                        
                        <p>{icon5des[indexTranslate]}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <div className="box-section-3 ">
                      <div className="box-content-section-3">
                        <div className="divIcon"> <AiOutlineForm className="icon"/> </div>
                        <h6>{icon2title[indexTranslate]}</h6>
                        
                        <p>{icon2des[indexTranslate]}</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="box-section-3 ">
                      <div className="box-content-section-3">
                        <div className="divIcon"> <AiOutlineFileSearch className="icon"/> </div>
                        <h6>{icon4title[indexTranslate]}</h6>
                        
                        <p>{icon4des[indexTranslate]}</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="box-section-3 ">
                      <div className="box-content-section-3">
                        <div className="divIcon"> <BiSupport className="icon"/> </div>
                        <h6>{icon6title[indexTranslate]}</h6>
                        
                        <p>{icon6des[indexTranslate]}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Container>
        </div>
      ) : (
        <div className="section-3" style={{ direction: "rtl" }}>
          <Container>
            <Row className="section-3-container">
              <Col lg="12">
                <Row>
                  <Col lg="12" > 
                    <h1 className="titleSection"> {title[indexTranslate]} </h1>
                    <div className="titleUnderLine" ></div>

                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <div className="box-section-3">
                      <div className="box-content-section-3">
                        
                        <div className="divIcon"> <TbReceiptTax className="icon"/> </div>

                        <h6>{icon1title[indexTranslate]}</h6>
                        
                        <p>{icon1des[indexTranslate]}</p>
                     
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="box-section-3 ">
                      <div className="box-content-section-3">
                        <div className="divIcon"> <HiCalculator className="icon"/> </div>
                        <h6>{icon3title[indexTranslate]}</h6>
                        
                        <p>{icon3des[indexTranslate]}</p>
                      </div>
                    </div>
                  </Col>
                  
                  <Col lg="4">
                    <div className="box-section-3 ">
                      <div className="box-content-section-3">
                        <div className="divIcon"> <AiOutlineAudit className="icon"/> </div>
                        <h6>{icon5title[indexTranslate]}</h6>
                        
                        <p>{icon5des[indexTranslate]}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <div className="box-section-3 ">
                      <div className="box-content-section-3">
                        <div className="divIcon"> <AiOutlineForm className="icon"/> </div>
                        <h6>{icon2title[indexTranslate]}</h6>
                        
                        <p>{icon2des[indexTranslate]}</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="box-section-3 ">
                      <div className="box-content-section-3">
                        <div className="divIcon"> <AiOutlineFileSearch className="icon"/> </div>
                        <h6>{icon4title[indexTranslate]}</h6>
                        
                        <p>{icon4des[indexTranslate]}</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="box-section-3 ">
                      <div className="box-content-section-3">
                        <div className="divIcon"> <BiSupport className="icon"/> </div>
                        <h6>{icon6title[indexTranslate]}</h6>
                        
                        <p>{icon6des[indexTranslate]}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default Section3;
