import { Tilt } from "react-tilt";
import "./whatsapp.css";
import { BsWhatsapp } from "react-icons/bs";
const Whatsapp = () => {
  return (
    <Tilt className="whats-app">
      <a
        href=" https://wa.me/971551204449?text=I'm interested to know more about the services" target="_blank"
        className="whatsapp"
      >
        <BsWhatsapp className="fs-4"/>
      </a>
    </Tilt>
  );
};

export default Whatsapp;
