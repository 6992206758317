import { Container, Row, Col } from "react-bootstrap";
import "./footer.css";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import { Link } from "react-router-dom";
import useGoToTop from "../../hook/useGoToTop";
import {
  BsFacebook,
  BsWhatsapp,
  BsLinkedin,
  BsInstagram,
  BsYoutube,
} from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";


const data = new Date();
let year = data.getFullYear();
const Footer = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const {
    ourTeams,
    contactUs,
    aboutUs,
    otherServices,
  } = {
    ...translate.nav,
  };
  const {
    takfil,
    contact,
    IMPORTANTLINKS,
    workTime,
    sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
  } = { ...translate.footer };
  return (
    <>
      <div className="footer">
        <Container>
          <Row>
            <Col lg="4" className="pt-5">
              <div className="box">
                <h3>{takfil[indexTranslate]}</h3>
                <ul className="social">
                  <li>
                    <a
                      href="https://www.facebook.com/KH.TaTc"
                      target="_blank"
                      rel="noreferrer"
                      className="facebook"
                    >
                      <BsFacebook />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/@kh.tatc"
                      target="_blank"
                      rel="noreferrer"
                      className="tiktok"
                    >
                      <FaTiktok />
                    </a>
                  </li>
                  <li>
                    <a
                      href=" https://wa.me/971551204449?text=I'm interested to know more about the services"
                      target="_blank"
                      rel="noreferrer"
                      className="whatsapp"
                    >
                      <BsWhatsapp />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/takfil-digital-marketing/"
                      target="_blank"
                      rel="noreferrer"
                      className="linkedin"
                    >
                      <BsLinkedin />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/kh.tatc/"
                      target="_blank"
                      rel="noreferrer"
                      className="instagram"
                    >
                      <BsInstagram />
                    </a>
                  </li>
                  <li>
                    <a href="/" className="youtube">
                      <BsYoutube />
                    </a>
                  </li>
                </ul>
                <p className="text">{contact[indexTranslate]}</p>
              </div>
            </Col>
            <Col lg="4" className="pt-5">
              {indexTranslate === 0 ? (
                <div className="box">
                  <h2> {IMPORTANTLINKS[indexTranslate]}</h2>
                  <ul className="links">
                    <li>
                    </li>
                    <li>
                      <Link to="/otherServices" onClick={useGoToTop}>
                        {otherServices[indexTranslate]}
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={useGoToTop}>
                        {ourTeams[indexTranslate]}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/contactUs" onClick={useGoToTop}>
                        {contactUs[indexTranslate]}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/aboutUs" onClick={useGoToTop}>
                        {aboutUs[indexTranslate]}
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="box" style={{ direction: "rtl" }}>
                  <h2> {IMPORTANTLINKS[indexTranslate]}</h2>
                  <ul className="links links-ar">
                    <li>
                    </li>
                    <li>
                      <Link to="/otherServices" onClick={useGoToTop}>
                        {otherServices[indexTranslate]}
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={useGoToTop}>
                        {ourTeams[indexTranslate]}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/contactUs" onClick={useGoToTop}>
                        {contactUs[indexTranslate]}
                      </Link>
                    </li>
                    <li>
                      <Link to="/aboutUs" onClick={useGoToTop}>
                        {aboutUs[indexTranslate]}
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </Col>
            <Col lg="4" className="pt-5">
              {indexTranslate === 0 ? (
                <div className="time-work">
                  <h2>{workTime[indexTranslate]}</h2>
                  <div className="line">
                    <i className="far fa-clock fa-fw"></i>
                    <span>{sunday[indexTranslate]} :</span>
                    <p>From 09:00 To 18:00</p>
                  </div>
                  <div className="line">
                    <i className="far fa-clock fa-fw"></i>
                    <span>{Monday[indexTranslate]} :</span>
                    <p>From 09:00 To 18:00</p>
                  </div>
                  <div className="line">
                    <i className="far fa-clock fa-fw"></i>
                    <span>{Tuesday[indexTranslate]} :</span>
                    <p>From 09:00 To 18:00</p>
                  </div>
                  <div className="line">
                    <i className="far fa-clock fa-fw"></i>
                    <span>{Wednesday[indexTranslate]} :</span>
                    <p>From 09:00 To 18:00</p>
                  </div>
                  <div className="line">
                    <i className="far fa-clock fa-fw"></i>
                    <span>{Thursday[indexTranslate]} :</span>
                    <p>From 09:00 To 18:00</p>
                  </div>
                  <div className="line">
                    <i className="far fa-clock fa-fw"></i>
                    <span>{Friday[indexTranslate]} :</span>
                    <p>From 09:00 To 18:00</p>
                  </div>
                </div>
              ) : (
                <div className="time-work" style={{ direction: "rtl" }}>
                  <h2>{workTime[indexTranslate]}</h2>
                  <div className="line">
                    <i className="far fa-clock fa-fw"></i>
                    <span>{sunday[indexTranslate]} :</span>
                    <p>من 09:00 إلى 18:00</p>
                  </div>
                  <div className="line">
                    <i className="far fa-clock fa-fw"></i>
                    <span>{Monday[indexTranslate]} :</span>
                    <p>من 09:00 إلى 18:00</p>
                  </div>
                  <div className="line">
                    <i className="far fa-clock fa-fw"></i>
                    <span>{Tuesday[indexTranslate]} :</span>
                    <p>من 09:00 To 18:00</p>
                  </div>
                  <div className="line">
                    <i className="far fa-clock fa-fw"></i>
                    <span>{Wednesday[indexTranslate]} :</span>
                    <p>من 09:00 إلى 18:00</p>
                  </div>
                  <div className="line">
                    <i className="far fa-clock fa-fw"></i>
                    <span>{Thursday[indexTranslate]} :</span>
                    <p>من 09:00 إلى 18:00</p>
                  </div>
                  <div className="line">
                    <i className="far fa-clock fa-fw"></i>
                    <span>{Friday[indexTranslate]} :</span>
                    <p>من 09:00 إلى 18:00</p>
                  </div>
                </div>
              )}
            </Col>
           </Row>

          <p className="copyright">
            © Copyright 2012 - {year} | Takfil Accounting & Tax Consultancy | All
            Rights Reserved | Powered by Takfil Digital Marketing <br />
            <span>
              <Link to="/privacy" onClick={useGoToTop}>
                Privacy Policy
              </Link>
              |
              <Link to="/terms" onClick={useGoToTop}>
                Terms & Conditions
              </Link>
            </span>
          </p>
        </Container>
      </div>
    </>
  );
};

export default Footer;
