import { Container, Row, Col } from "react-bootstrap";
import "./about.css";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import about from "../../assets/images/logo.png";
import takfilTaxImg from "../../assets/images/logo.png";
import takfilServicesDocsImg from "../../assets/images/takfil-services-document.webp";
import takfilTDMImg from "../../assets/images/takfilTDM.webp";
import Header from "../../components/utility/header/Header";
import { Helmet } from "react-helmet-async";
const AboutUs = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const { aboutUsTATC, desAboutUsTATC,
      theVisionTATC, desTheVisionTATC,
      ourValuesTATC, desOurValuesTATC,
      titleTDM, desTDM, titleTDCS, desTDCS,
      theMessageTATC, desTheMessageTATC } = { ...translate.aboutUs };
  return (
    <>
      {indexTranslate === 0 ? (
        <Helmet>
          <meta
            name="title"
            content="About Takfil Accounting Services | Leading Financial Experts in the UAE"
          />
          <meta
            name="description"
            content="Explore the story behind Takfil Accounting Services, a prominent financial consulting company in the United Arab Emirates.
             Learn about our team of qualified chartered accountants and tax experts dedicated to providing exceptional financial solutions.
             Discover our commitment to building long-term relationships based on trust and credibility."
          />
          <meta
            name="keywords"
            content="About Takfil, Financial Experts UAE, Chartered Accountants Team, Tax Consulting Firm, Company Values,
             Trustworthy Financial Solutions, Credible Financial Consultants, Commitment to Excellence, UAE Financial Services."
          />
          <title>Takfil Accounting Services | About Us</title>
        </Helmet>
      ) : (
        <Helmet>
          <meta
            name="title"
            content="عن تكفيل للخدمات المحاسبية | كبار الخبراء الماليين في دولة الإمارات العربية المتحدة"
          /> 
          <meta
            name="description"
            content="اكتشف قصة تكفيل للخدمات المحاسبية، وهي شركة استشارات مالية بارزة في دولة الإمارات العربية المتحدة.
            تعرف على فريقنا من المحاسبين القانونيين المؤهلين وخبراء الضرائب المخصصين لتقديم حلول مالية استثنائية.
            اكتشف التزامنا ببناء علاقات طويلة الأمد مبنية على الثقة والمصداقية."
          />
          <meta
            name="keywords"
            content="عن تكفيل، الخبراء الماليون في الإمارات العربية المتحدة، فريق المحاسبين القانونيين، شركة الاستشارات الضريبية، قيم الشركة،
            حلول مالية جديرة بالثقة، مستشارون ماليون موثوقون، الالتزام بالتميز، الخدمات المالية في دولة الإمارات العربية المتحدة."
          />

          <title>  تكفيل للخدمات المحاسبية | عن الشركة </title>
        </Helmet>
      )}
      {indexTranslate === 0 ? (
        <div className="about-us">
          <Header
            link="/aboutUs"
            secondPage={translate.nav.aboutUs[indexTranslate]}
          />
          <div className="about-us-section-1 ">
            <Container>
              <Row >
                <Col lg="6" sm="12" xs="12">
                  <img src={takfilTaxImg} className="imgs" />
                </Col>
                <Col lg="6" sm="12" xs="12" className="about-us-content mt-5 mb-5">
                  <h1 className="mb-4">{translate.nav.TATC[indexTranslate]}</h1>
                  <h2 className="about-us-title">{aboutUsTATC[indexTranslate]}</h2>
                  <div className="about-us-description">
                    {desAboutUsTATC[indexTranslate]}
                  </div>

                  <h2 className="about-us-title">{theVisionTATC[indexTranslate]}</h2>
                  <div className="about-us-description">
                    {desTheVisionTATC[indexTranslate]}
                  </div>

                  <h2 className="about-us-title">{theMessageTATC[indexTranslate]}</h2>
                  <div className="about-us-description">
                    {desTheMessageTATC[indexTranslate]}
                  </div>

                  <h2 className="about-us-title">{ourValuesTATC[indexTranslate]}</h2>
                  <div className="about-us-description">
                    {desOurValuesTATC[indexTranslate]}
                  </div>

                </Col>
              </Row>
              <Row >
                <Col lg="6" sm="12" xs="12">
                  <img src={takfilTDMImg} className="imgs" />
                </Col>
                <Col lg="6" sm="12" xs="12" className="about-us-content  mt-5 mb-5">
                  <h1 className="mb-4">{titleTDM[indexTranslate]}</h1>
                  <div className="about-us-description">
                    {desTDM[indexTranslate]}
                  </div>
                  <a  href="https://takfil.ae" className="link-website">
                    View Website
                  </a>
                </Col>
              </Row>

              <Row >
                <Col lg="6" sm="12" xs="12">
                  <img src={takfilServicesDocsImg} className="imgs" />
                </Col>
                <Col lg="6" sm="12" xs="12" className="about-us-content  mt-5 mb-5">
                  <h1 className="mb-4">{titleTDCS[indexTranslate]}</h1>
                  <div className="about-us-description">
                    {desTDCS[indexTranslate]}
                  </div>
                  <a  href="https://takfil.ae" className="link-website">
                    View Website
                  </a>
                </Col>
              </Row>
            </Container>
          </div>

        </div>
      ) : (
        <div className="about-us" style={{ direction: "rtl" }}>
          <Header
            link="/aboutUs"
            secondPage={translate.nav.aboutUs[indexTranslate]}
          />
          <div className="about-us-section-1 ">
            <Container>
              <Row >
                <Col lg="6" sm="12" xs="12">
                  <img src={takfilTaxImg} className="imgs" />
                </Col>
                <Col lg="6" sm="12" xs="12" className="about-us-content mt-5 mb-5">
                  <h1 className="mb-4">{translate.nav.TATC[indexTranslate]}</h1>
                  <h2 className="about-us-title">{aboutUsTATC[indexTranslate]}</h2>
                  <div className="about-us-description">
                    {desAboutUsTATC[indexTranslate]}
                  </div>

                  <h2 className="about-us-title">{theVisionTATC[indexTranslate]}</h2>
                  <div className="about-us-description">
                    {desTheVisionTATC[indexTranslate]}
                  </div>

                  <h2 className="about-us-title">{theMessageTATC[indexTranslate]}</h2>
                  <div className="about-us-description">
                    {desTheMessageTATC[indexTranslate]}
                  </div>

                  <h2 className="about-us-title">{ourValuesTATC[indexTranslate]}</h2>
                  <div className="about-us-description">
                    {desOurValuesTATC[indexTranslate]}
                  </div>

                </Col>
              </Row>
              <Row >
                <Col lg="6" sm="12" xs="12">
                  <img src={takfilTDMImg} className="imgs" />
                </Col>
                <Col lg="6" sm="12" xs="12" className="about-us-content  mt-5 mb-5">
                  <h1 className="mb-4">{titleTDM[indexTranslate]}</h1>
                  <div className="about-us-description">
                    {desTDM[indexTranslate]}
                  </div>
                  <a  href="https://takfil.ae" className="link-website">
                    رابط الموقع
                  </a>
                </Col>
              </Row>

              <Row >
                <Col lg="6" sm="12" xs="12">
                  <img src={takfilServicesDocsImg} className="imgs" />
                </Col>
                <Col lg="6" sm="12" xs="12" className="about-us-content  mt-5 mb-5">
                  <h1 className="mb-4">{titleTDCS[indexTranslate]}</h1>
                  <div className="about-us-description">
                    {desTDCS[indexTranslate]}
                  </div>
                  <a  href="https://takfil.ae" className="link-website">
                  رابط الموقع
                  </a>
                </Col>
              </Row>
            </Container>
          </div>

        </div>
      )}
    </>
  );
};

export default AboutUs;
