import { Link } from "react-router-dom";
import "./gototop.css";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import { useEffect } from "react";

const GoToTop = () => {
  useEffect(() => {
    let calcScrollValue = () => {
      let scrollProgress = document.getElementById("progress");
      let pos = document.documentElement.scrollTop;
      let calcHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      let scrollValue = Math.round((pos * 100) / calcHeight);
      if (pos > 100) {
        scrollProgress.style.display = "grid";
      } else {
        scrollProgress.style.display = "none";
      }
      scrollProgress.addEventListener("click", () => {
        document.documentElement.scrollTop = 0;
      });
      scrollProgress.style.background = `conic-gradient(#dd6d0e ${scrollValue}%, #ffffff ${scrollValue}%)`;
    };

    window.onscroll = calcScrollValue;
    window.onload = calcScrollValue;
  }, []);
  return (
    <div id="progress">
      <span id="progress-value">
        <BsFillArrowUpCircleFill />
      </span>
    </div>
  );
};

export default GoToTop;
