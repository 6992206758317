import React from "react";
import "./section2.css";
import { Container, Row, Col } from "react-bootstrap";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import { Tilt } from "react-tilt";
import img from '../../assets/images/consultant.webp';


const Section2 = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const {
    des    
  } = {
    ...translate.section2,
  };
  return (
    <>
      {indexTranslate === 0 ? (
        <div className="section-2"> 
          
          <h1 className="section2-des">{translate.section2.des[indexTranslate]}</h1>
         
        </div>
      ) : (
        <div className="section-2" style={{ direction: "rtl" }}>
          <h1 className="section2-des">{translate.section2.des[indexTranslate]}</h1>
        </div>
      )}
    </>
  );
};

export default Section2;
