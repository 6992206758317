import './advertisingPosts.css'
import AdvertisingPost1 from '../../assets/images/Advertising/advertising1.webp' 
import AdvertisingPost2 from '../../assets/images/Advertising/advertising2.webp' 
import AdvertisingPost3 from '../../assets/images/Advertising/advertising3.webp' 
import AdvertisingPost4 from '../../assets/images/Advertising/advertising4.webp' 
import AdvertisingPost5 from '../../assets/images/Advertising/advertising5.webp' 
import AdvertisingPost6 from '../../assets/images/Advertising/advertising6.webp' 
import AdvertisingPost7 from '../../assets/images/Advertising/advertising7.webp' 
import AdvertisingPost8 from '../../assets/images/Advertising/advertising8.webp' 
import AdvertisingPost9 from '../../assets/images/Advertising/advertising9.webp' 
import AdvertisingPost10 from '../../assets/images/Advertising/advertising10.webp' 
import AdvertisingPost11 from '../../assets/images/Advertising/advertising11.webp' 
import AdvertisingPost12 from '../../assets/images/Advertising/advertising12.webp' 
import AdvertisingPost13 from '../../assets/images/Advertising/advertising13.webp' 
import AdvertisingPost14 from '../../assets/images/Advertising/advertising14.webp' 
import AdvertisingPost15 from '../../assets/images/Advertising/advertising15.webp' 
import AdvertisingPost16 from '../../assets/images/Advertising/advertising16.webp' 
import AdvertisingPost17 from '../../assets/images/Advertising/advertising17.webp' 
import AdvertisingPost18 from '../../assets/images/Advertising/advertising18.webp' 
import AdvertisingPost19 from '../../assets/images/Advertising/advertising19.webp' 
import AdvertisingPost20 from '../../assets/images/Advertising/advertising20.webp' 
import AdvertisingPost21 from '../../assets/images/Advertising/advertising21.webp' 
import AdvertisingPost22 from '../../assets/images/Advertising/advertising22.webp' 
import AdvertisingPost23 from '../../assets/images/Advertising/advertising23.webp' 
import AdvertisingPost24 from '../../assets/images/Advertising/advertising24.webp' 
import AdvertisingPost25 from '../../assets/images/Advertising/advertising25.webp' 
import AdvertisingPost26 from '../../assets/images/Advertising/advertising26.webp' 
import AdvertisingPost27 from '../../assets/images/Advertising/advertising27.webp' 
import AdvertisingPost28 from '../../assets/images/Advertising/advertising28.webp' 
import AdvertisingPost29 from '../../assets/images/Advertising/advertising29.webp' 
import AdvertisingPost30 from '../../assets/images/Advertising/advertising30.webp' 
import AdvertisingPost31 from '../../assets/images/Advertising/advertising31.webp' 
import AdvertisingPost32 from '../../assets/images/Advertising/advertising32.webp' 
import AdvertisingPost33 from '../../assets/images/Advertising/advertising33.webp' 
import AdvertisingPost34 from '../../assets/images/Advertising/advertising34.webp' 
import AdvertisingPost35 from '../../assets/images/Advertising/advertising35.webp' 
import AdvertisingPost36 from '../../assets/images/Advertising/advertising36.webp' 
import AdvertisingPost37 from '../../assets/images/Advertising/advertising37.webp' 
import AdvertisingPost38 from '../../assets/images/Advertising/advertising38.webp' 
import AdvertisingPost39 from '../../assets/images/Advertising/advertising39.webp' 
import AdvertisingPost40 from '../../assets/images/Advertising/advertising40.webp' 
import AdvertisingPost41 from '../../assets/images/Advertising/advertising41.webp' 




import IndexTranslate from '../../atoms/translateIndex'
import Frame from '../../components/utility/frame/Frame'
import { Col, Container, Row } from 'react-bootstrap'
import { useRecoilState } from 'recoil'
import { audazImages } from '../compaines/Audaz/Audaz'
import { biggestCoinImages } from '../compaines/biggestCoin/BiggestCoin'
import { bioGharImages } from '../compaines/BioGhar/BioGhar'
import { kenzImages } from '../compaines/kenz/Kenz'
import { qasrImages } from '../compaines/qasr-alrand/QasrAlrand'



const images=[
    AdvertisingPost1,
    AdvertisingPost2,
    AdvertisingPost3,
    AdvertisingPost4 ,
    AdvertisingPost5,
    AdvertisingPost6,
    AdvertisingPost7,
    AdvertisingPost8,
    AdvertisingPost9,
    AdvertisingPost10,
    AdvertisingPost11,
    AdvertisingPost12,
    AdvertisingPost13,
    AdvertisingPost14,
    AdvertisingPost15,
    AdvertisingPost16,
    AdvertisingPost17,
    AdvertisingPost18,
    AdvertisingPost19,
    AdvertisingPost20,
    AdvertisingPost21,
    AdvertisingPost22,
    AdvertisingPost23,
    AdvertisingPost24,
    AdvertisingPost25,
    AdvertisingPost26,
    AdvertisingPost27,
    AdvertisingPost28,
    AdvertisingPost29,
    AdvertisingPost30,
    AdvertisingPost31,
    AdvertisingPost32,
    AdvertisingPost33,
    AdvertisingPost34,
    AdvertisingPost35,
    AdvertisingPost36,
    AdvertisingPost37,
    AdvertisingPost38,
    AdvertisingPost39,
    AdvertisingPost40,
    AdvertisingPost41,
    ...audazImages.slice(0,6),
    ...biggestCoinImages,
    ...bioGharImages.slice(0,4),
    ...kenzImages,
    ...qasrImages.slice(0,7),

]
const AdvertisingPosts = () => {
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <div className="logos-page">
    {
        indexTranslate===0? <Frame title="Advertising Post" />: <Frame title=" منشورات اعلانية" />
    }
     
      <Container>
      <Row>
       {
        images.map(img=>{
            return(
               <Col md='3' className="logos-image" key={img}>
               <div >
               <img src={img} alt="" />
               </div>
               </Col>
            )
        })
       }
      </Row>
      </Container>
    </div>
  )
}

export default AdvertisingPosts
