import { Tilt } from 'react-tilt'
import './frame.css'
const Frame = ({title}) => {
  return (
   <>
  <Tilt className='frame-title'>
    <h3 className='title'>{title}</h3>
   
  </Tilt>
   </>
  )
}

export default Frame
