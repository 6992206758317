import React from "react";
import Location from "../../components/location/Location";
import MainSection from "../../components/main/MainSection";
import OurCustomers from "../../components/our-customers/OurCustomers";
import Plans from "../../components/plans/Plans";
import Section2 from "../../components/section-2/Section2";
import Section3 from "../../components/section-3/Section3";
import Section4 from "../../components/section4slider/Section4";
import Testimonials from "../../components/Testimonials/Testimonials";
import { Helmet } from "react-helmet-async";
import IndexTranslate from "../../atoms/translateIndex";
import { useRecoilState } from "recoil";
import CountSection from "../../components/Count-section/CountSection";


const HomePage = () => {
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <>
      {indexTranslate === 0 ? (
        <Helmet>
          <meta
            name="description"
            content="Discover unparalleled financial expertise with Takfil Accounting Services,
             a top-tier accounting and financial consulting firm in the UAE.
             Our qualified chartered accountants and tax experts offer tailored solutions for small and medium-sized companies,
              fostering long-term relationships built on trust, credibility, and commitment."
          />
          <meta
            name="keywords"
            content="
            Accounting Services UAE, Financial Consulting, Tax Solutions, Chartered Accountants, Small Business Finance, 
            Medium-sized Companies, Trustworthy Financial Advice, Credible Financial Consultants, Commitment to Excellence.
            "
          ></meta>

          <meta property="og:site_name" content="takfil" />
          <meta
            property="og:title"
            content="Takfil Accounting Services | Expert Financial Solutions in UAE"
          />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="./logo.png" />
          <meta property="og:url" content="https://www.takfil.ae" />

          <title>Takfil Accounting Services | Home </title>
        </Helmet>
      ) : (
        <Helmet>
          <meta
            name="description"
            content="اكتشف خبرة مالية لا مثيل لها مع خدمات تكفيل المحاسبية،
            شركة استشارات محاسبية ومالية رفيعة المستوى في دولة الإمارات العربية المتحدة.
            يقدم المحاسبون القانونيون المؤهلون وخبراء الضرائب لدينا حلولاً مخصصة للشركات الصغيرة والمتوسطة الحجم،
             تعزيز العلاقات طويلة الأمد المبنية على الثقة والمصداقية والالتزام."
          />
          <meta
            name="description"
            content=" التسويق الرقمي لنشاط التجاري وزيادة العائد الخاص بك "
          />
          <meta
            name="keywords"
            content="خدمات المحاسبة في الإمارات العربية المتحدة، الاستشارات المالية، الحلول الضريبية، المحاسبون القانونيون،
             تمويل الأعمال الصغيرة، الشركات المتوسطة الحجم، استشارات مالية جديرة بالثقة، مستشارون ماليون موثوقون، الالتزام بالتميز."
          ></meta>
          <meta property="og:site_name" content="takfil" />
          <meta
            property="og:title"
            content="تكفيل للخدمات المحاسبية | خبراء الحلول المالية في دولة الإمارات العربية المتحدة"
          />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="./logo.png" />
          <meta property="og:url" content="https://www.takfiltax.net" />
          <title> تكفيل للخدمات المحاسبية | الرئيسية </title>
        </Helmet>
      )}
 
  
      <div className="parent-div">
        <MainSection />
        <Section3 />
        <Section2 />
        <Testimonials />
        <Section4 />
        <Location />
      </div>
    

    </>
  );
};

export default HomePage;
