import "./location.css";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import Frame from "../utility/frame/Frame";
const Location = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <div className="map">
      <div className="title-map text-center">
        <h1> {translate.location.title[indexTranslate]} </h1>
        <h3> {translate.nav.TATC[indexTranslate]} </h3>
      </div>
      <div className="map-container col-lg-12 wow animate__backInRight">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d14433.289788234079!2d55.322039!3d25.2597363!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3e5f5dabf251e9bb%3A0xe7a70658086e778b!2z2KrZg9mB2YrZhCDZhNmE2K3Ys9in2KjYp9iqINmI2KfZhNil2LPYqti02KfYsdin2Kog2KfZhNi22LHZitio2YrYqSwgQjYyIC0gQWwgTWFrdG91bSBSZCAtIERlaXJhIC0gUmlnZ2F0IEFsIEJ1dGVlbiAtIER1YmFp!3m2!1d25.2597363!2d55.322039!5e0!3m2!1sen!2sae!4v1690291507952!5m2!1sen!2sae"
          style={{ border: 0 }}
          allowFullScreen=""
          referrerPolicy="no-referrer-when-downgrade"
          title="map-2"
        ></iframe>
      </div>
    </div>
  );
};

export default Location;
