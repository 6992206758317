import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import { Container } from "react-bootstrap";
import "./testimonials.css";
import review1 from "../../assets/images/review1.webp";
import review2 from "../../assets/images/review2.webp";
import review3 from "../../assets/images/review3.webp";
import review4 from "../../assets/images/review4.webp";
import review5 from "../../assets/images/review5.webp";
import TestSlider from "../testimonialsSlider/TestSlider.js";
import Frame from "../utility/frame/Frame";
const Testimonials = () => {
  const images = [review1, review2, review3, review4, review5];
  const texts = [
    "Quick service...my fines were reduced and work completed in short time ",
    "Best services",
    "من افضل شركات والمصداقيه وانا تعاملت معاهم",
    " Great service",
    "شركة ذات خبرة وكفاءة جيده وفقكم الله مشكورين",
  ];
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);

  const { title } = { ...translate.testimonials };
  return (
    <div className="testimonials">
      <Container>
          <h3>{title[indexTranslate]}</h3>
      
        <TestSlider images={images} texts={texts} />
      </Container>
    </div>
  );
};

export default Testimonials;
