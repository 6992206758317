import "./App.css";
import HomePage from "./pages/Home/HomePage";
import { Routes, Route } from "react-router-dom";
import Navigation from "./components/nav/Navigation";
import Footer from "./components/Footer/Footer";
import OtherServices from "./pages/otherServices/OtherServices";
import ContactUs from "./pages/Contact-us/ContactUs";
import AboutUs from "./pages/about-us/AboutUs";
import Privacy from "./pages/privacy/Privacy";
import Terms from "./pages/terms/Terms";
import Portfolio from "./pages/portfolio/Portfolio";
import Audaz from "./pages/compaines/Audaz/Audaz";
import QasrAlrand from "./pages/compaines/qasr-alrand/QasrAlrand";
import ShamMovers from "./pages/compaines/sham/ShamMovers";
import Kenz from "./pages/compaines/kenz/Kenz";
import Windex from "./pages/compaines/windex/Windex";
import BiggestCoin from "./pages/compaines/biggestCoin/BiggestCoin";
import NotFound from "./pages/NotFound/NotFound";
import BioGhar from "./pages/compaines/BioGhar/BioGhar";
import Logos from "./pages/logos/Logos";
import AdvertisingPosts from "./pages/AdvertisingPosts/AdvertisingPosts";
import Publications from "./pages/Publications/Publications";
import { motion, useScroll } from "framer-motion";
import GoToTop from "./components/go to top/GoToTop";
import Offer from "./components/offer/Offer";
import Whatsapp from "./components/whatsapp/Whatsapp";
import WebDevelopment from "./pages/webdevelopment/WebDevelopment";

function App() {
  const { scrollYProgress } = useScroll();
  return (
    <>
      <div className="App">
        <Navigation />
        <motion.div
          style={{ scaleX: scrollYProgress }}
          className="progress-bar"
        />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/otherServices" element={<OtherServices />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/company/audaz" element={<Audaz />} />
          <Route path="/company/Qasr-Alrand" element={<QasrAlrand />} />
          <Route path="/company/ShamMovers" element={<ShamMovers />} />
          <Route path="/company/Kenz" element={<Kenz />} />
          <Route path="/company/Windex" element={<Windex />} />
          <Route path="/company/biggest-coin" element={<BiggestCoin />} />
          <Route path="/company/bioghar" element={<BioGhar />} />
          <Route path="/company/Logos" element={<Logos />} />
          <Route
            path="/company/AdvertisingPosts"
            element={<AdvertisingPosts />}
          />
          <Route path="/company/Publication" element={<Publications />} />
          <Route path="/portfolio/websites" element={<WebDevelopment />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Whatsapp />
        <GoToTop/>
        <Footer />
      </div>
    </>
  );
}

export default App;
